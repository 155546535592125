// @ts-nocheck

import Web3 from 'web3';
import { useEffect } from 'react';

export function addCustomNetwork() {
  // Check if MetaMask is installed
  if (!window.ethereum) {
    console.error('MetaMask is not installed');
    return;
  }

  // Define the custom network parameters
  const customNetwork = {
    chainId: '0x2645', // Chain ID of the custom network
    chainName: 'Optimus Network', // Name of the custom network
    nativeCurrency: {
      name: 'OZ7',
      symbol: 'OZ7',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.optimusz7.com'], // RPC URL of the custom network
    blockExplorerUrls: ['https://explorer.optimusz7.com'], // Block Explorer URL of the custom network
  };

  // Add the custom network
  window.ethereum
    .request({
      method: 'wallet_addEthereumChain',
      params: [customNetwork],
    })
    .then(() => {
      console.log('Custom network added successfully');
    })
    .catch((error) => {
      console.error('Error adding custom network:', error);
    });
}
